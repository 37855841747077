import Vue from 'vue'
import vSelect from 'vue-select'

import 'vue-select/dist/vue-select.css'

vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement('i', { class: { 'bx bx-x select-icon': true } })
  },
  OpenIndicator: {
    render: createElement => createElement('i', { class: { 'bx bx-chevron-down select-icon': true } })
  }
})

Vue.component('Select', vSelect)
