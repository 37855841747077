export default function({ $swal }, inject) {
  inject('toast', $swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    customClass: {
      title: 'swal2-modern-title',
      icon: 'swal2-modern-icon',
      container: 'swal2-modern-container'
    },
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', $swal.stopTimer)
      toast.addEventListener('mouseleave', $swal.resumeTimer)
    }
  }))
  inject('notify', $swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    customClass: {
      title: 'swal2-modern-title',
      icon: 'swal2-modern-icon',
      container: 'swal2-modern-container'
    }
  }))
}
