import * as cryptoJS from 'crypto-js'

export default (context, inject) => {
  const i18n = context.app.i18n
  if (process.client) {
    ;(function() {
      const throttle = function(type, name, obj) {
        const object = obj || window
        let running = false
        const func = function() {
          if (running) { return }
          running = true
          requestAnimationFrame(function() {
            object.dispatchEvent(new CustomEvent(name))
            running = false
          })
        }
        object.addEventListener(type, func)
      }
      throttle('scroll', 'optimizedScroll')
    })()
  }
  function changeNullValues(jsonObj) {
    for (const property in jsonObj) {
      jsonObj[property] = jsonObj[property] !== null ? jsonObj[property] : ''
    }
    return jsonObj
  }
  inject('changeNullValues', changeNullValues)
  function fixedEncodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
      return '%' + c.charCodeAt(0).toString(16)
    })
  }
  function getTokenHeader(dataObject) {
    const params = new URLSearchParams(changeNullValues(dataObject))
    return cryptoJS
      .HmacSHA512(
        fixedEncodeURIComponent(params.toString()).toLowerCase(),
        context.$config.appSecret
      )
      .toString()
  }
  inject('getTokenHeader', getTokenHeader)
  function isProduction() {
    return context.$config.appEnv === 'production'
  }
  inject('isProduction', isProduction)
  inject('getBigNumber', function(number, digits = 1) {
    if (number < 1000) {
      return number
    }
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'common.k' },
      { value: 1e6, symbol: 'common.M' },
      { value: 1e9, symbol: 'common.B' },
      { value: 1e12, symbol: 'common.T' }
    ]
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    const item = lookup.slice().reverse().find(function(item) {
      return number >= item.value
    })
    return item ? (number / item.value).toFixed(digits).replace(rx, '$1') + i18n.t(item.symbol) : '0'
  })
  inject('getFormatDate', function(dateInfo, withTime = true) {
    if (dateInfo === null) {
      return '...'
    }

    const dateNew = new Date(dateInfo)
    let response =
      ('0' + dateNew.getDate()).slice(-2) +
      '.' +
      ('0' + (dateNew.getMonth() + 1)).slice(-2) +
      '.' +
      dateNew.getFullYear()
    if (withTime) {
      response += ', ' +
        (dateNew.getHours() < 10 ? '0' : '') +
        dateNew.getHours() +
        ':' +
        (dateNew.getMinutes() < 10 ? '0' : '') +
        dateNew.getMinutes()
    }
    return response
  })
  function getDateDayAndMonth(dateInfo) {
    if (dateInfo === null) {
      return '...'
    }

    const dateNew = new Date(dateInfo)
    return (
      ('0' + dateNew.getDate()).slice(-2) +
      '.' +
      ('0' + (dateNew.getMonth() + 1)).slice(-2)
    )
  }
  inject('getDateDayAndMonth', getDateDayAndMonth)
  function formatSpaces(number, isMoney = false) {
    if (number === undefined || number === null) {
      return '...'
    }
    if (isMoney) {
      number = Math.round((number + Number.EPSILON) * 100) / 100
    }
    const afterComma = number.toString().split('.')
    const result = Number(afterComma[0]).toFixed(0)
    if (afterComma[1] !== undefined && afterComma[1] !== '00') {
      return (
        result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\xA0') +
        '.' +
        afterComma[1]
      )
    } else {
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\xA0')
    }
  }
  inject('formatSpaces', formatSpaces)
  function formatCryptoAmount(number, max = null, isNumber = false) {
    const initNumber = parseFloat(number)
    if (isNaN(initNumber)) {
      return '...'
    }
    if (initNumber === 0) {
      return '0'
    }
    if (number !== null && number !== undefined && typeof initNumber === 'number') {
      const stringAmount = initNumber.toString().split('.')
      const decPoint = isNumber ? '' : ','
      if (initNumber > 1) {
        return priceFormat(initNumber, 2, '.', decPoint)
      }
      if (stringAmount[1] !== undefined) {
        if (!stringAmount[1].includes('e-')) {
          let zerosAfterComma = 0
          const detailedAmount = initNumber.toPrecision(20).toString().split('.')
          for (const char of detailedAmount[1].split('')) {
            if (char === '0') {
              ++zerosAfterComma
            } else {
              break
            }
          }
          if (zerosAfterComma === detailedAmount[1].length) {
            zerosAfterComma = 0
          }
          if (zerosAfterComma > 0) {
            let denominator = zerosAfterComma
            if (max !== null && zerosAfterComma > max) {
              denominator = max
            } else {
              denominator += 2
            }
            return number !== null ? priceFormat(initNumber, denominator, '.', decPoint) : '...'
          } else {
            return number !== null ? priceFormat(initNumber, 2, '.', decPoint) : '...'
          }
        } else {
          const numbersArray = number.toString().split('e-')
          const zerosCount = parseInt(numbersArray[1], 10)
          const lastNumbers = numbersArray[0].replace('.', '').length
          const zerosGenerate = max !== null && (zerosCount + lastNumbers > max) ? max - lastNumbers : zerosCount
          const finalNumberOffset = zerosCount - zerosGenerate
          const meaningfulNumber = finalNumberOffset > 0 ? numbersArray[0].replace('.', '').slice(0, -finalNumberOffset) : numbersArray[0].replace('.', '')
          if (!isNumber) {
            return meaningfulNumber.length > 0 ? '0.' + '0'.repeat(zerosGenerate) + meaningfulNumber : '0'
          } else {
            return meaningfulNumber.length > 0 ? parseFloat('0.' + '0'.repeat(zerosGenerate) + meaningfulNumber) : 0
          }
        }
      } else if (stringAmount[0].includes('e-')) {
        const mainNumbers = stringAmount[0].split('e-')
        return '0.' + '0'.repeat(parseInt(mainNumbers[1], 10)) + mainNumbers[0]
      } else {
        return number
      }
    } else {
      return '...'
    }
  }
  inject('formatCryptoAmount', formatCryptoAmount)
  function cutZeros(number, limit = 4) {
    const stringAmounts = number.toString().split('.')
    if (stringAmounts[1] !== undefined) {
      let zerosAfterComma = 0
      for (const char of stringAmounts[1].split('')) {
        if (char === '0') {
          ++zerosAfterComma
        } else {
          break
        }
      }
      return zerosAfterComma > limit ? stringAmounts[0] + '.0...0' + stringAmounts[1].slice(zerosAfterComma) : number
    } else {
      return number
    }
  }
  inject('cutZeros', cutZeros)
  function priceFormat(number, decimals, decPoint = '.', thousandSep = ',') {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
    const n = !isFinite(+number) ? 0 : +number
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
    const sep = (typeof thousandSep === 'undefined') ? ',' : thousandSep
    const dec = (typeof decPoint === 'undefined') ? '.' : decPoint
    let s = ''
    const toFixedFix = function(n, prec) {
      const k = Math.pow(10, prec)
      return '' + Math.round(n * k) / k
    }

    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }
    if ((s[1] || '').length < prec) {
      s[1] = s[1] || ''
      s[1] += new Array(prec - s[1].length + 1).join('0')
    }
    return s.join(dec)
  }
  inject('priceFormat', priceFormat)
  inject('getErrorsText', (errors) => {
    const textWarning = []
    if (Object.keys(errors).length > 0) {
      for (const erorr in errors) {
        textWarning.push(errors[erorr][0])
      }
    } else if (errors.length > 0) {
      for (let itr = 0; itr < errors.length; ++itr) {
        textWarning.push(errors[itr])
      }
    }
    return textWarning
  })
  inject('isAddress', function(address) {
    if (!/^(0x)?[0-9a-f]{40}$/i.test(address)) {
      return false
    } else if (/^(0x)?[0-9a-f]{40}$/.test(address) || /^(0x)?[0-9A-F]{40}$/.test(address)) {
      return true
    } else {
      return isChecksumAddress(address)
    }
  })
  function isValidUrl(string) {
    let url

    try {
      url = new URL(string)
    } catch (_) {
      return false
    }

    return url.protocol === 'http:' || url.protocol === 'https:'
  }
  inject('isValidUrl', isValidUrl)
  function checkTheme() {
    const currentTheme = context.app.$cookies.get('siteTheme')
    if (currentTheme === undefined) {
      context.app.$cookies.set('siteTheme', 'light')
      document.querySelector('html').classList.add('light')
    } else {
      setTheme(currentTheme)
    }
  }
  inject('checkTheme', checkTheme)
  function changeTheme(forceTheme = null) {
    checkTheme()
    let currentTheme = context.app.$cookies.get('siteTheme')
    if (forceTheme !== null) {
      currentTheme = forceTheme
    } else {
      currentTheme = currentTheme === 'dark' ? 'light' : 'dark'
    }
    context.app.$cookies.set('siteTheme', currentTheme)
    context.app.store.commit('setTheme', currentTheme)
    setTheme(currentTheme)
  }
  inject('changeTheme', changeTheme)
  function checkCurrency() {
    const currency = context.app.$cookies.get('siteCurrency')
    if (currency === undefined) {
      context.app.$cookies.set('siteCurrency', 'usd')
    }
  }
  inject('checkCurrency', checkCurrency)
  function changeCurrency(newCurrency) {
    checkCurrency()
    let currentCurrency = context.app.$cookies.get('siteCurrency')
    if (newCurrency !== null && currentCurrency.toLowerCase() !== newCurrency.toLowerCase()) {
      currentCurrency = newCurrency.toLowerCase()
    }
    context.app.$cookies.set('siteCurrency', currentCurrency)
  }
  inject('changeCurrency', changeCurrency)
  function setTheme(currentTheme) {
    document.querySelector('html').classList.remove('dark')
    document.querySelector('html').classList.remove('light')
    document.querySelector('html').classList.add(currentTheme)
  }
  inject('setTheme', setTheme)
  function isChecksumAddress(address) {
    address = address.replace('0x', '')
    const addressHash = cryptoJS.SHA3(address.toLowerCase())
    for (let ind = 0; ind < 40; ++ind) {
      if ((parseInt(addressHash[ind], 16) > 7 && address[ind].toUpperCase() !== address[ind]) || (parseInt(addressHash[ind], 16) <= 7 && address[ind].toLowerCase() !== address[ind])) {
        return false
      }
    }
    return true
  }
  inject('isChecksumAddress', isChecksumAddress)
  inject('shortenTitle', function(title, chars = 16) {
    if (title !== undefined && title !== null) {
      return (title.length > chars) ? title.substr(0, chars) + '…' : title
    } else {
      return ''
    }
  })
  inject('validateEmail', function(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  })
  inject('shortenDescription', function(description) {
    if (description !== undefined && description !== null) {
      return (description.length > 70) ? description.substr(0, 70) + '…' : description
    } else {
      return ''
    }
  })
  function changeImageExtension(fileItem, webpSupport = false, isThumb = false) {
    const fileExtesion = fileItem.substr(fileItem.lastIndexOf('.') + 1, fileItem.length - fileItem.lastIndexOf('.') + 1) || fileItem
    const extension = webpSupport ? 'webp' : fileExtesion
    if (!isThumb) {
      return fileItem.substr(0, fileItem.length - fileExtesion.length) + extension
    } else {
      return fileItem.substr(0, fileItem.length - fileExtesion.length - 1) + '_thumb.' + extension
    }
  }
  inject('changeImageExtension', changeImageExtension)
  function getProjectCategory(type) {
    if (type !== null && type !== undefined) {
      let link = type
      switch (type.toLowerCase()) {
        case 'defi':
          link = 'defi'
          break
        case 'exchange':
          link = 'exchanges'
          break
        case 'token':
        case 'coin':
        case 'cefi':
        case 'token_sale':
        default:
          link = 'tokens'
      }
      return link
    } else {
      return type
    }
  }
  inject('getProjectCategory', getProjectCategory)
  function supportsWebp() {
    if (process.client && document !== undefined) {
      const elem = document.createElement('canvas')
      if (elem.getContext && elem.getContext('2d')) {
        return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
      } else {
        return false
      }
    } else {
      return false
    }
  }
  inject('supportsWebp', supportsWebp)
  inject('staticImage', (fileItem) => {
    if (fileItem === undefined || fileItem === null || (fileItem !== undefined && fileItem.length === 0)) {
      return fileItem
    }
    return '/' + changeImageExtension(fileItem, supportsWebp(), false)
  })
  inject('getImage', (fileItem, entity = 'projects', isThumb = false) => {
    if (fileItem === undefined || fileItem === null || (fileItem !== undefined && fileItem !== null && fileItem.length === 0)) {
      return context.$config.mediaUrl + 'img/' + entity + '/default.webp'
    }
    if (isValidUrl(fileItem)) {
      return fileItem
    }
    if (fileItem.includes('ipfs://')) {
      return fileItem.replace('ipfs://', 'https://ipfs.io/')
    }
    if (fileItem.includes('default')) {
      isThumb = false
    }
    if (fileItem.match(/\.(jpeg|jpg|png)$/) !== null) {
      return context.$config.mediaUrl + 'img/' + changeImageExtension(fileItem, supportsWebp(), isThumb)
    } else {
      return context.$config.mediaUrl + 'img/' + fileItem
    }
  })
  inject('shortenAddress', (address) => {
    if (address !== undefined && address !== null && address.length > 10) {
      return address.substr(0, 8) + '...' + address.substr(-5)
    } else {
      return ''
    }
  })
  inject('calculateUSD', (amount, tokenPrice) => {
    return amount * tokenPrice
  })
  function numberToString(number, textForms) {
    number = Math.abs(number) % 100
    const numberForm = number % 10
    if (number > 10 && number < 20) {
      return textForms[2]
    } else if (numberForm > 1 && numberForm < 5) {
      return textForms[1]
    } else if (numberForm === 1) {
      return textForms[0]
    }
    return textForms[2]
  }
  inject('numberToString', numberToString)
  function beautifyDate(date) {
    if (date === null) {
      return '...'
    }

    const dateObj = date !== 'now' ? new Date(date) : new Date()
    const nowTime = new Date()
    let hours = dateObj.getHours()
    const timeText = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours !== 0 ? hours : 12
    if (dateObj.getDate() === nowTime.getDate() &&
    dateObj.getMonth() === nowTime.getMonth() &&
    dateObj.getFullYear() === nowTime.getFullYear()) {
      return hours + ':' + (dateObj.getMinutes() < 10 ? '0' : '') + dateObj.getMinutes() + ' ' + timeText
    } else {
      return (
        ('0' + dateObj.getDate()).slice(-2) +
        '.' +
        ('0' + (dateObj.getMonth() + 1)).slice(-2) +
        '.' +
        dateObj.getFullYear() +
        ', ' +
        ' ' +
        hours + ':' +
        (dateObj.getMinutes() < 10 ? '0' : '') +
        dateObj.getMinutes() + ' ' + timeText
      )
    }
  }
  inject('beautifyDate', beautifyDate)
  inject('realisticDate', (date) => {
    if (date === null) {
      return '...'
    }

    const sentDate = new Date(date)
    let seconds = Math.floor((new Date() - sentDate) / 1000)
    let dateText = 'past'
    if (sentDate > new Date()) {
      seconds = Math.floor((sentDate - new Date()) / 1000)
      dateText = 'future'
    }
    let interval = seconds / 31536000
    if (interval > 1) {
      return beautifyDate(date)
    }
    interval = seconds / 2592000
    if (interval > 1) {
      const monthsText = numberToString(Math.floor(interval), [i18n.t('common.month'), i18n.t('common.months'), i18n.t('common.many_months')])
      return dateText === 'past'
        ? beautifyDate(date)
        : i18n.t('common.in') + ' ' + Math.floor(interval) + ' ' + monthsText
    }
    interval = seconds / 86400
    if (interval > 1) {
      const daysText = numberToString(Math.floor(interval), [i18n.t('common.day'), i18n.t('common.days'), i18n.t('common.many_days')])
      return dateText === 'past'
        ? beautifyDate(date)
        : i18n.t('common.in') + ' ' + Math.floor(interval) + ' ' + daysText
    }
    interval = seconds / 3600
    if (interval > 1) {
      const hoursText = numberToString(Math.floor(interval), [i18n.t('common.hour'), i18n.t('common.hours'), i18n.t('common.many_hours')])
      return dateText === 'past'
        ? Math.floor(interval) + ' ' + hoursText + ' ' + i18n.t('common.ago')
        : i18n.t('common.in') + ' ' + Math.floor(interval) + ' ' + hoursText
    }
    interval = seconds / 60
    if (interval > 1) {
      const minutesText = numberToString(Math.floor(interval), [i18n.t('common.minute'), i18n.t('common.minutes'), i18n.t('common.many_minutes')])
      return dateText === 'past'
        ? Math.floor(interval) + ' ' + minutesText + ' ' + i18n.t('common.ago')
        : i18n.t('common.in') + ' ' + Math.floor(interval) + ' ' + minutesText
    }
    const secondsText = numberToString(Math.floor(interval), [i18n.t('common.second'), i18n.t('common.seconds'), i18n.t('common.many_seconds')])
    return dateText === 'past'
      ? Math.floor(interval) + ' ' + secondsText + ' ' + i18n.t('common.ago')
      : i18n.t('common.in') + ' ' + Math.floor(interval) + ' ' + secondsText
  })
  inject('sortObjectsArrayByField', (array, field) => {
    return array.sort((first, second) => (first[field] > second[field]) ? 1 : ((second[field] > first[field]) ? -1 : 0))
  })
  inject('shortenDate', (date, secondDate = null) => {
    if (date === null) {
      return '...'
    }

    const dateStarts = date !== 'now' ? new Date(date) : new Date()
    const dateEnds = secondDate !== null ? new Date(secondDate) : null
    const monthNames = [
      i18n.t('common.jan'),
      i18n.t('common.feb'),
      i18n.t('common.mar'),
      i18n.t('common.apr'),
      i18n.t('common.may'),
      i18n.t('common.jun'),
      i18n.t('common.jul'),
      i18n.t('common.aug'),
      i18n.t('common.sep'),
      i18n.t('common.oct'),
      i18n.t('common.nov'),
      i18n.t('common.dec')
    ]

    let answer = ('0' + dateStarts.getDate()).slice(-2) + ' ' + monthNames[dateStarts.getMonth()]
    if (dateEnds !== null && dateStarts.getFullYear() !== dateEnds.getFullYear()) {
      answer += ', ' + dateStarts.getFullYear()
    }
    return answer
  })
  inject('stringifyDate', (date, withTime = false) => {
    if (date === null) {
      return '...'
    }

    const dateNew = new Date(date)
    const monthNames = [
      i18n.t('common.january'),
      i18n.t('common.february'),
      i18n.t('common.march'),
      i18n.t('common.april'),
      i18n.t('common.may'),
      i18n.t('common.june'),
      i18n.t('common.july'),
      i18n.t('common.august'),
      i18n.t('common.september'),
      i18n.t('common.october'),
      i18n.t('common.november'),
      i18n.t('common.december')
    ]

    if (!withTime) {
      return monthNames[dateNew.getMonth()] + ' ' + ('0' + dateNew.getDate()).slice(-2) + ', ' + dateNew.getFullYear()
    } else {
      const ampm = dateNew.getHours() >= 12 ? 'PM' : 'AM'
      const hours = dateNew.getHours() % 12
      return monthNames[dateNew.getMonth()] + ' ' + ('0' + dateNew.getDate()).slice(-2) + ', ' + dateNew.getFullYear() +
        ' ' + i18n.t('common.at') + ' ' + (hours !== 0 ? hours : 12) + ':' + ('0' + dateNew.getMinutes()).slice(-2) +
        ' ' + ampm
    }
  })
  inject('timeFromDate', (date) => {
    if (date === null) {
      return '...'
    }

    const dateNew = new Date(date)
    const ampm = dateNew.getHours() >= 12 ? 'PM' : 'AM'
    const hours = dateNew.getHours() % 12
    return (hours !== 0 ? hours : 12) + ':' + ('0' + dateNew.getMinutes()).slice(-2) + ' ' + ampm
  })
  function treatAsUTC(date) {
    const result = new Date(date)
    return result.setMinutes(result.getMinutes() - result.getTimezoneOffset())
  }
  inject('treatAsUTC', treatAsUTC)
  inject('daysBetweenDates', (startDate, endDate) => {
    const millisecondsPerDay = 24 * 60 * 60 * 1000
    return Math.round(Math.abs(treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay)
  })
  inject('checkIfImage', (file) => {
    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/webp']
    return file !== undefined && file !== null && acceptedImageTypes.includes(file.fileType)
  })
  inject('checkIfImageByExtension', (filePath) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp']
    if (imageExtensions.some(item => filePath.includes('.' + item))) {
      return true
    } else {
      return false
    }
  })
  inject('checkIfExpired', (date) => {
    const currentDate = new Date()
    const sentDate = new Date(date)
    if (sentDate < currentDate) {
      return true
    } else {
      return false
    }
  })
  function clearCookies() {
    const accept = context.app.$cookies.get('cookiesAccept')
    const recentSearches = context.app.$cookies.get(context.$config.searchesCookieName)
    context.app.$cookies.removeAll()
    context.app.$cookies.set('cookiesAccept', accept)
    context.app.$cookies.set(context.$config.searchesCookieName, recentSearches)
  }
  inject('clearCookies', clearCookies)
  function getBlockchain(blockchainId) {
    switch (parseInt(blockchainId, 10)) {
      case 1:
        return 'Bitcoin'
      case 2:
        return 'Ethereum'
      case 3:
        return 'Tether USD'
      case 4:
        return 'USD Dollar'
      case 5:
        return 'Bitcoin Cash'
      case 6:
        return 'Litecoin'
      case 7:
        return 'Ripple'
      case 8:
        return 'Binance Smart Chain'
      case 9:
        return 'Solana'
      case 10:
        return 'Tezos'
      case 11:
        return 'Flow'
      case 12:
        return 'Polygon'
      default:
        return i18n.t('common.unrecognized')
    }
  }
  inject('getBlockchain', getBlockchain)
  function getExplorerByCode(blockchain) {
    if (blockchain !== undefined && blockchain !== null) {
      switch (blockchain.toLowerCase()) {
        case 'eth':
          return 'htts://etherscan.io/'
        case 'bnb':
          return 'htts://bscscan.com/'
        case 'sol':
          return 'https://solscan.io/'
        case 'matic':
          return 'htts://polygonscan.com/'
        case 'flow':
          return 'https://flowscan.org/'
        case 'xtz':
          return 'https://tzkt.io/'
      }
    } else {
      return ''
    }
  }
  inject('getExplorerByCode', getExplorerByCode)
  function getExplorerNameByCode(blockchain) {
    if (blockchain !== undefined && blockchain !== null) {
      switch (blockchain.toLowerCase()) {
        case 'eth':
          return 'Etherscan'
        case 'bnb':
          return 'Bscscan'
        case 'sol':
          return 'Solscan'
        case 'matic':
          return 'Polygonscan'
        case 'flow':
          return 'Flowscan'
        case 'xtz':
          return 'Tzkt'
      }
    } else {
      return ''
    }
  }
  inject('getExplorerNameByCode', getExplorerNameByCode)
  function getBlockchainCode(blockchainId) {
    switch (parseInt(blockchainId, 10)) {
      case 1:
        return 'BTC'
      case 2:
        return 'ETH'
      case 3:
        return 'USDT'
      case 4:
        return 'USD'
      case 5:
        return 'BCH'
      case 6:
        return 'LTC'
      case 7:
        return 'XRP'
      case 8:
        return 'BNB'
      case 9:
        return 'SOL'
      case 10:
        return 'XTZ'
      case 11:
        return 'FLOW'
      case 12:
        return 'MATIC'
      default:
        return 'TBD'
    }
  }
  inject('getBlockchainCode', getBlockchainCode)
  function getExplorerByBid(blockchainId) {
    switch (parseInt(blockchainId, 10)) {
      case 2:
        return 'https://etherscan.io'
      case 8:
        return 'https://bscscan.com'
      case 9:
        return 'https://explorer.solana.com'
      case 12:
        return 'https://polygonscan.com'
      default:
        return 'https://etherscan.io'
    }
  }
  inject('getExplorerByBid', getExplorerByBid)
  inject('getPriceIcon', (tokenCode, isWhite = false) => {
    const folderName = !isWhite ? '/blockchains/' : '/blockchains/white/'
    if (tokenCode === undefined || tokenCode === null) {
      return changeImageExtension(context.$config.mediaUrl + 'img' + folderName + 'eth.png', supportsWebp(), false)
    }
    return changeImageExtension(context.$config.mediaUrl + 'img' + folderName + tokenCode.toLowerCase() + '.png', supportsWebp(), false)
  })
  inject('getBlockchainIcon', (tokenCode) => {
    if (tokenCode !== undefined && tokenCode !== null && typeof tokenCode === 'string') {
      return changeImageExtension('/blockchains/' + tokenCode.toLowerCase() + '.png', supportsWebp(), false)
    } else {
      return changeImageExtension('/blockchains/default.svg', supportsWebp(), false)
    }
  })
  function setHeaders(headers) {
    const userToken = context.app.store.getters['auth/userToken']
    if (userToken !== undefined && userToken !== null && userToken.length > 0) {
      headers.authorization = `Bearer ${userToken}`
    }
    return headers
  }
  inject('setHeaders', setHeaders)
  function blockchainSlugToCurrency(blockchain) {
    if (blockchain !== undefined && blockchain !== null) {
      switch (blockchain.toLowerCase()) {
        case 'ethereum':
          return 'ETH'
        case 'binance':
          return 'BNB'
        case 'polygon':
          return 'MATIC'
        case 'solana':
          return 'SOL'
        case 'tezos':
          return 'XTZ'
        case 'flow':
          return 'FLOW'
      }
    } else {
      return ''
    }
  }
  inject('blockchainSlugToCurrency', blockchainSlugToCurrency)
  function checkProtocol(url) {
    return url.includes('http://') || url.includes('https://') ? url : 'https://' + url
  }
  inject('checkProtocol', checkProtocol)
  function blockchainTitle(chain) {
    if (chain !== null && chain !== undefined) {
      switch (chain.toLowerCase()) {
        case 'ethereum':
        case 'solana':
        case 'polygon':
        case 'bitcoin':
        case 'flow':
        case 'litecoin':
        case 'avalanche':
        case 'ronin':
        case 'enjin':
        case 'wemix':
        case 'cardano':
        case 'polkadot':
        case 'heco':
        case 'terra':
        case 'hive':
        case 'vechain':
        case 'kardia':
        case 'xaya':
        case 'steem':
        case 'waves':
        case 'klaytn':
        case 'loom':
        case 'harmony':
        case 'fantom':
        case 'myria':
        case 'marblex':
        case 'bora':
          return this.$ucfirst(chain)
        case 'tron':
        case 'wax':
        case 'eos':
        case 'neo':
        case 'zenzo':
        case 'poa':
        case 'bsv':
        case 'iost':
        case 'oec':
          return chain.toUpperCase()
        case 'binance':
          return 'Binance Smart Chain'
        case 'immutable':
          return 'Immutable X'
        case 'gala':
          return 'Gala Games'
        case 'chain':
          return 'Chain Games'
        case 'phantasma':
          return 'Phantasma Chain'
        case 'vulcan':
          return 'Vulcan Forged'
        case 'other':
          return this.$t('filter.other')
        case 'all':
          return this.$t('filter.all')
        default:
          return ''
      }
    } else {
      return ''
    }
  }
  inject('blockchainTitle', blockchainTitle)
  function closeModals() {
    context.app.store.commit('modal/closeAll')
  }
  inject('closeModals', closeModals)
  function generateApiRequest() {
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'API-VERSION': '2'
    }
    headers = setHeaders(headers)
    const api = context.$axios.create({
      baseURL: process.env.apiUrl,
      debug: false, // must be FALSE in prod
      headers
    })
    api.onRequest((config) => {
      api.setHeader('API-VERSION', '2')
      if (config.data === undefined) {
        api.setHeader('X-COINS-TOKEN', getTokenHeader(config.params))
      } else {
        api.setHeader('X-COINS-TOKEN', getTokenHeader(config.data))
      }
    })

    return api
  }
  inject('generateApiRequest', generateApiRequest)
  function getImageMeta(url) {
    try {
      const img = new Image()
      img.addEventListener('load', function() {
        return {
          width: this.naturalWidth,
          height: this.naturalHeight
        }
      })
      img.src = url
    } catch (err) {
      console.log(err)
      return {
        width: 0,
        height: 0
      }
    }
  }
  inject('getImageMeta', getImageMeta)
  function refreshCookieExpires() {
    const nowDate = new Date()
    nowDate.setHours(nowDate.getHours() + this.$config.cookieExpires)

    const userData = context.app.$cookies.get('auth.user')
    const tokenData = context.app.$cookies.get('auth.token')

    if (userData !== undefined && userData !== null) {
      context.app.$cookies.set('auth.user', userData, {
        expires: nowDate,
        path: '/'
      })
    }
    if (tokenData !== undefined && tokenData !== null) {
      context.app.$cookies.set('auth.token', tokenData, {
        expires: nowDate,
        path: '/'
      })
    }
  }
  inject('refreshCookieExpires', refreshCookieExpires)
  function getProjectLink(type, slug) {
    switch (type.toLowerCase()) {
      case 'token_sale':
      case 'coin':
      case 'token':
        return '/tokens/' + slug
      case 'exchange':
        return '/exchanges/' + slug
      case 'defi':
        return '/defi/' + slug
      case 'cefi':
        return '/cefi/' + slug
      case 'crypto_bank':
        return '/banks/' + slug
      case 'nft':
        return '/nft/' + slug
    }
  }
  inject('getProjectLink', getProjectLink)
  function getUserRole(role) {
    if (role === undefined || role === null) {
      return ''
    }
    switch (role.toLowerCase()) {
      case 'personal':
        return i18n.t('auth.personal')
      case 'legal':
        return i18n.t('auth.legal')
    }
  }
  inject('getUserRole', getUserRole)
  function ucfirst(string) {
    return string !== undefined && string !== null ? string.charAt(0).toUpperCase() + string.slice(1) : ''
  }
  inject('ucfirst', ucfirst)
  function searchEnableLabels() {
    const inputElems = document.querySelectorAll('.m-field-input')
    for (let ind = 0; ind < inputElems.length; ++ind) {
      activateLabel(inputElems[ind])
    }
  }
  inject('searchEnableLabels', searchEnableLabels)
  function activateLabel(inputEl) {
    if (inputEl !== null) {
      if (inputEl.parentElement !== null && inputEl.parentElement.getElementsByTagName('label')[0] !== undefined) {
        if (inputEl.value !== undefined && inputEl.value.length > 0) {
          inputEl.parentElement.getElementsByTagName('label')[0].classList.add('active')
        } else {
          inputEl.parentElement.getElementsByTagName('label')[0].classList.remove('active')
        }
      }
    }
  }
  inject('activateLabel', activateLabel)
  async function checkNetworkWhitelisted(networkIds = []) {
    const currentChain = await window.eth.eth.net.getId()
    return Array.isArray(networkIds) ? networkIds.includes(parseInt(currentChain, 10)) : false
  }
  inject('checkNetworkWhitelisted', checkNetworkWhitelisted)
  async function replaceChain(newChain = null) {
    const currentChain = newChain !== null ? newChain : context.$config.ethChainId
    if (window.ethereum !== undefined && parseInt(window.ethereum.networkVersion) !== parseInt(currentChain, 10) && !isNaN(parseInt(currentChain, 10))) {
      let status = null
      await window.ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: window.eth.utils.toHex(currentChain)
            }
          ]
        })
        .then((success) => {
          if (success == null) {
            window.$nuxt.$emit('modalInfo', i18n.t('common.network_has_been_changed'))
            status = currentChain
            context.$cookies.set('chainId', currentChain)
          } else {
            window.$nuxt.$emit('modalInfo', i18n.t('common.deined_operation'))
          }
        })
        .catch((err) => {
          if (err.message !== 'Request method wallet_switchEthereumChain is not supported') {
            window.$nuxt.$emit('modalInfo', i18n.t('common.deined_operation') + ': ' + err.message)
          }
        })
      return status
    } else {
      return null
    }
  }
  inject('replaceChain', replaceChain)
  function getNetwork(networkId) {
    switch (parseInt(networkId, 10)) {
      case 1:
      case 4:
      case 5:
      case 11155111:
        return 'Ethereum'
      case 137:
      case 80001:
        return 'Polygon'
      case 56:
      case 97:
        return 'Binance Smart Chain'
      case 128:
      case 256:
        return 'Huobi'
      default:
        return i18n.t('common.unrecognized')
    }
  }
  inject('getNetwork', getNetwork)
  function isNetworkTestnet(networkId) {
    switch (parseInt(networkId, 10)) {
      case 1:
        return false
      case 11155111:
        return true
      default:
        return true
    }
  }
  inject('isNetworkTestnet', isNetworkTestnet)
  function checkCurrentChain() {
    if (window.ethereum !== undefined &&
        window.ethereum.networkVersion !== undefined &&
        !isNaN(parseInt(window.ethereum.networkVersion)) &&
        checkNetworkWhitelisted(parseInt(window.ethereum.networkVersion))) {
      replaceChain()
    }
  }
  inject('checkCurrentChain', checkCurrentChain)
  inject('getUserAddress', async (silent = false) => {
    if (window.eth === undefined) {
      if (!silent) {
        window.$nuxt.$emit('modalInfo', i18n.t('common.install_wallet_text'), i18n.t('common.install_wallet'), {
          route: 'https://metamask.io/download/',
          title: i18n.t('common.install_metamask')
        })
      }
      return ''
    }
    const walletType = context.$cookies.get('recent')
    const metamaskAddress = walletType !== 'wallet_connect' ? (await window.eth.eth.requestAccounts()) : (await window.eth.eth.getAccounts())[0]
    const authUserAddress = context.app.store.getters['auth/user'] !== undefined ? context.app.store.getters['auth/user'] : null
    const cookieAddress = context.app.$cookies.get('auth.user')
    if (authUserAddress !== undefined && authUserAddress !== null) {
      return metamaskAddress[0]
    } else if (metamaskAddress.length === 0 && !silent) {
      window.$nuxt.$emit('modalInfo', i18n.t('common.no_selected_wallet_text'), i18n.t('common.no_selected_wallet'))
    } else if (((authUserAddress !== null && authUserAddress.address === null) || (cookieAddress !== null && cookieAddress.address === null)) && !silent) {
      window.$nuxt.$emit('modalInfo', i18n.t('common.link_address_text'), i18n.t('common.link_address'), {
        route: '/account/settings',
        title: i18n.t('common.link_wallet')
      })
    } else if (!silent) {
      window.$nuxt.$emit('modalInfo', i18n.t('common.selected_address_incorrect_text'), i18n.t('common.selected_address_incorrect'))
    }
    return ''
  })
  async function getScanLink(bid = null) {
    const currentBid = bid !== null ? Number(bid) : Number(await window.eth.eth.net.getId())
    return new Promise((resolve) => {
      switch (currentBid) {
        case 1:
          resolve('https://etherscan.io/')
          break
        case 11155111:
          resolve('https://sepolia.etherscan.io/')
          break
        case 137:
          resolve('https://polygonscan.com/')
          break
        case 80001:
          resolve('https://testnet.polygonscan.com/')
          break
        case 56:
          resolve('https://bscscan.com/')
          break
        case 97:
          resolve('https://testnet.bscscan.com/')
          break
        default:
          resolve(null)
      }
    })
  }
  inject('getScanLink', getScanLink)
  async function calculateGasCost() {
    const currentBid = Number(await window.eth.eth.getChainId())
    const defaultPrice = Number(await window.eth.eth.getGasPrice())
    return new Promise((resolve) => {
      switch (currentBid) {
        case 1:
        case 11155111:
          resolve(defaultPrice)
          break
        case 137:
          context.$axios.get('https://gasstation-mainnet.matic.network/v2').then((result) => {
            try {
              const calculatedVal = Math.round(result.data.fast.maxFee * 1.25)
              resolve(window.eth.utils.toWei(calculatedVal.toString(), 'gwei'))
            } catch (err) {
              console.log(err)
              resolve(45)
            }
          }).catch((err) => {
            console.log(err)
            resolve(defaultPrice)
          })
          break
        case 80001:
          context.$axios.get('https://gasstation-mumbai.matic.today/v2').then((result) => {
            try {
              const calculatedVal = Math.round(result.data.fast.maxFee * 1.25)
              resolve(window.eth.utils.toWei(calculatedVal.toString(), 'gwei'))
            } catch (err) {
              console.log(err)
              resolve(45)
            }
          }).catch((err) => {
            console.log(err)
            resolve(defaultPrice)
          })
          break
        case 56:
          context.$axios.get('https://api.bscscan.com/api', {
            params: {
              module: 'gastracker',
              action: 'gasoracle',
              apikey: context.$config.bscscanApiKey
            }
          }).then((result) => {
            if (result.result !== undefined && result.result.FastGasPrice !== undefined) {
              const calculatedVal = Math.round(parseFloat(result.result.FastGasPrice) * 1.1)
              resolve(window.eth.utils.toWei(calculatedVal.toString(), 'gwei'))
            } else {
              resolve(defaultPrice)
            }
          }).catch((err) => {
            console.log(err)
            resolve(defaultPrice)
          })
          break
        case 97:
          resolve(defaultPrice)
          break
        default:
          resolve(null)
      }
    })
  }
  inject('calculateGasCost', calculateGasCost)
  function getCountryCodes() {
    return {
      AF: 'Afghanistan',
      AL: 'Albania',
      DZ: 'Algeria',
      AS: 'American Samoa',
      AD: 'Andorra',
      AO: 'Angola',
      AI: 'Anguilla',
      AQ: 'Antarctica',
      AG: 'Antigua and Barbuda',
      AR: 'Argentina',
      AM: 'Armenia',
      AW: 'Aruba',
      AU: 'Australia',
      AT: 'Austria',
      AZ: 'Azerbaijan',
      BS: 'Bahamas (the)',
      BH: 'Bahrain',
      BD: 'Bangladesh',
      BB: 'Barbados',
      BY: 'Belarus',
      BE: 'Belgium',
      BZ: 'Belize',
      BJ: 'Benin',
      BM: 'Bermuda',
      BT: 'Bhutan',
      BO: 'Bolivia (Plurinational State of)',
      BQ: 'Bonaire, Sint Eustatius and Saba',
      BA: 'Bosnia and Herzegovina',
      BW: 'Botswana',
      BV: 'Bouvet Island',
      BR: 'Brazil',
      IO: 'British Indian Ocean Territory (the)',
      BN: 'Brunei Darussalam',
      BG: 'Bulgaria',
      BF: 'Burkina Faso',
      BI: 'Burundi',
      CV: 'Cabo Verde',
      KH: 'Cambodia',
      CM: 'Cameroon',
      CA: 'Canada',
      KY: 'Cayman Islands (the)',
      CF: 'Central African Republic (the)',
      TD: 'Chad',
      CL: 'Chile',
      CN: 'China',
      CX: 'Christmas Island',
      CC: 'Cocos (Keeling) Islands (the)',
      CO: 'Colombia',
      KM: 'Comoros (the)',
      CD: 'Congo (the Democratic Republic of the)',
      CG: 'Congo (the)',
      CK: 'Cook Islands (the)',
      CR: 'Costa Rica',
      HR: 'Croatia',
      CU: 'Cuba',
      CW: 'Curaçao',
      CY: 'Cyprus',
      CZ: 'Czechia',
      CI: "Côte d'Ivoire",
      DK: 'Denmark',
      DJ: 'Djibouti',
      DM: 'Dominica',
      DO: 'Dominican Republic (the)',
      EC: 'Ecuador',
      EG: 'Egypt',
      SV: 'El Salvador',
      GQ: 'Equatorial Guinea',
      ER: 'Eritrea',
      EE: 'Estonia',
      SZ: 'Eswatini',
      ET: 'Ethiopia',
      FK: 'Falkland Islands (the) [Malvinas]',
      FO: 'Faroe Islands (the)',
      FJ: 'Fiji',
      FI: 'Finland',
      FR: 'France',
      GF: 'French Guiana',
      PF: 'French Polynesia',
      TF: 'French Southern Territories (the)',
      GA: 'Gabon',
      GM: 'Gambia (the)',
      GE: 'Georgia',
      DE: 'Germany',
      GH: 'Ghana',
      GI: 'Gibraltar',
      GR: 'Greece',
      GL: 'Greenland',
      GD: 'Grenada',
      GP: 'Guadeloupe',
      GU: 'Guam',
      GT: 'Guatemala',
      GG: 'Guernsey',
      GN: 'Guinea',
      GW: 'Guinea-Bissau',
      GY: 'Guyana',
      HT: 'Haiti',
      HM: 'Heard Island and McDonald Islands',
      VA: 'Holy See (the)',
      HN: 'Honduras',
      HK: 'Hong Kong',
      HU: 'Hungary',
      IS: 'Iceland',
      IN: 'India',
      ID: 'Indonesia',
      IR: 'Iran (Islamic Republic of)',
      IQ: 'Iraq',
      IE: 'Ireland',
      IM: 'Isle of Man',
      IL: 'Israel',
      IT: 'Italy',
      JM: 'Jamaica',
      JP: 'Japan',
      JE: 'Jersey',
      JO: 'Jordan',
      KZ: 'Kazakhstan',
      KE: 'Kenya',
      KI: 'Kiribati',
      KP: "Korea (the Democratic People's Republic of)",
      KR: 'Korea (the Republic of)',
      KW: 'Kuwait',
      KG: 'Kyrgyzstan',
      LA: "Lao People's Democratic Republic (the)",
      LV: 'Latvia',
      LB: 'Lebanon',
      LS: 'Lesotho',
      LR: 'Liberia',
      LY: 'Libya',
      LI: 'Liechtenstein',
      LT: 'Lithuania',
      LU: 'Luxembourg',
      MO: 'Macao',
      MG: 'Madagascar',
      MW: 'Malawi',
      MY: 'Malaysia',
      MV: 'Maldives',
      ML: 'Mali',
      MT: 'Malta',
      MH: 'Marshall Islands (the)',
      MQ: 'Martinique',
      MR: 'Mauritania',
      MU: 'Mauritius',
      YT: 'Mayotte',
      MX: 'Mexico',
      FM: 'Micronesia (Federated States of)',
      MD: 'Moldova (the Republic of)',
      MC: 'Monaco',
      MN: 'Mongolia',
      ME: 'Montenegro',
      MS: 'Montserrat',
      MA: 'Morocco',
      MZ: 'Mozambique',
      MM: 'Myanmar',
      NA: 'Namibia',
      NR: 'Nauru',
      NP: 'Nepal',
      NL: 'Netherlands (the)',
      NC: 'New Caledonia',
      NZ: 'New Zealand',
      NI: 'Nicaragua',
      NE: 'Niger (the)',
      NG: 'Nigeria',
      NU: 'Niue',
      NF: 'Norfolk Island',
      MP: 'Northern Mariana Islands (the)',
      NO: 'Norway',
      OM: 'Oman',
      PK: 'Pakistan',
      PW: 'Palau',
      PS: 'Palestine, State of',
      PA: 'Panama',
      PG: 'Papua New Guinea',
      PY: 'Paraguay',
      PE: 'Peru',
      PH: 'Philippines (the)',
      PN: 'Pitcairn',
      PL: 'Poland',
      PT: 'Portugal',
      PR: 'Puerto Rico',
      QA: 'Qatar',
      MK: 'Republic of North Macedonia',
      RO: 'Romania',
      RU: 'Russian Federation (the)',
      RW: 'Rwanda',
      RE: 'Réunion',
      BL: 'Saint Barthélemy',
      SH: 'Saint Helena, Ascension and Tristan da Cunha',
      KN: 'Saint Kitts and Nevis',
      LC: 'Saint Lucia',
      MF: 'Saint Martin (French part)',
      PM: 'Saint Pierre and Miquelon',
      VC: 'Saint Vincent and the Grenadines',
      WS: 'Samoa',
      SM: 'San Marino',
      ST: 'Sao Tome and Principe',
      SA: 'Saudi Arabia',
      SN: 'Senegal',
      RS: 'Serbia',
      SC: 'Seychelles',
      SL: 'Sierra Leone',
      SG: 'Singapore',
      SX: 'Sint Maarten (Dutch part)',
      SK: 'Slovakia',
      SI: 'Slovenia',
      SB: 'Solomon Islands',
      SO: 'Somalia',
      ZA: 'South Africa',
      GS: 'South Georgia and the South Sandwich Islands',
      SS: 'South Sudan',
      ES: 'Spain',
      LK: 'Sri Lanka',
      SD: 'Sudan (the)',
      SR: 'Suriname',
      SJ: 'Svalbard and Jan Mayen',
      SE: 'Sweden',
      CH: 'Switzerland',
      SY: 'Syrian Arab Republic',
      TW: 'Taiwan',
      TJ: 'Tajikistan',
      TZ: 'Tanzania, United Republic of',
      TH: 'Thailand',
      TL: 'Timor-Leste',
      TG: 'Togo',
      TK: 'Tokelau',
      TO: 'Tonga',
      TT: 'Trinidad and Tobago',
      TN: 'Tunisia',
      TR: 'Turkey',
      TM: 'Turkmenistan',
      TC: 'Turks and Caicos Islands (the)',
      TV: 'Tuvalu',
      UG: 'Uganda',
      UA: 'Ukraine',
      AE: 'United Arab Emirates (the)',
      GB: 'United Kingdom of Great Britain and Northern Ireland (the)',
      UM: 'United States Minor Outlying Islands (the)',
      US: 'United States of America (the)',
      UY: 'Uruguay',
      UZ: 'Uzbekistan',
      VU: 'Vanuatu',
      VE: 'Venezuela (Bolivarian Republic of)',
      VN: 'Viet Nam',
      VG: 'Virgin Islands (British)',
      VI: 'Virgin Islands (U.S.)',
      WF: 'Wallis and Futuna',
      EH: 'Western Sahara',
      YE: 'Yemen',
      ZM: 'Zambia',
      ZW: 'Zimbabwe',
      AX: 'Åland Islands'
    }
  }
  inject('getCountryCodes', getCountryCodes)
}
