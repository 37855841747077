export default async function({ app, route, redirect }) {
  const user = await app.$cookies.get('auth.user')
  const token = await app.$cookies.get('auth.token')
  const isLoginPage = route.name.includes('login___')
  if (isLoginPage && user !== undefined && token !== undefined && token.length > 0 && Object.keys(user).length > 0) {
    redirect(app.localePath('/account'))
  } else if (isLoginPage) {
    return
  }
  if (user === undefined || token === undefined || token.length === 0 || Object.keys(user).length === 0) {
    console.log('AUTH blocked by middleware, redirecting')
    redirect(app.localePath('/login'))
  }
}
