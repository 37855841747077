export default async function({ app, route, redirect }) {
  const isLoginPage = route.fullPath === '/login'
  if (isLoginPage) {
    return
  }
  const user = await app.$cookies.get('auth.user')
  const token = await app.$cookies.get('XSRF-TOKEN')
  const tokenAuth = await app.$cookies.get('auth._token.laravelSanctum')
  if ((user === undefined || token === undefined || (tokenAuth === undefined && tokenAuth !== true)) &&
    route.fullPath.includes('account')) {
    console.log('GUEST blocked by middleware, redirecting')
    redirect(app.localePath('/login'))
  }
}
