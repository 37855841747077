import Vue from 'vue'
import VuePictureSwipe from 'vue-picture-swipe'

Vue.component('ImagesGallery', {
  components: {
    VuePictureSwipe
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    options: {
      type: Object,
      required: false,
      default() {
        return {
          bgOpacity: 0.75,
          shareEl: false,
          zoomEl: true
        }
      }
    }
  },
  render(createElement) {
    return createElement('VuePictureSwipe', {
      attrs: {
        items: this.items,
        options: this.options
      }
    })
  }
})
