export default function({ $axios, $getTokenHeader, $setHeaders }, inject) {
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
  headers = $setHeaders(headers)
  const api = $axios.create({
    baseURL: process.env.apiUrl,
    debug: false, // must be FALSE in prod
    headers
  })

  api.onRequest((config) => {
    api.setHeader('API-VERSION', '2')
    if (config.data === undefined) {
      api.setHeader('X-COINS-TOKEN', $getTokenHeader(config.params))
    } else {
      api.setHeader('X-COINS-TOKEN', $getTokenHeader(config.data))
    }
  })

  const upload = $axios.create({
    baseURL: process.env.apiUrl,
    debug: false, // must be FALSE in prod
    headers
  })

  upload.onRequest((config) => {
    api.setHeader('API-VERSION', '2')
    if (config.data === undefined) {
      upload.setHeader('X-COINS-TOKEN', $getTokenHeader(config.params))
    } else {
      upload.setHeader('X-COINS-TOKEN', $getTokenHeader(config.data))
    }
  })

  const load = $axios.create({
    baseURL: process.env.apiUrl,
    debug: false, // must be FALSE in prod
    headers,
    responseType: 'blob'
  })

  load.onRequest((config) => {
    if (config.data === undefined) {
      upload.setHeader('X-COINS-TOKEN', $getTokenHeader(config.params))
    } else {
      upload.setHeader('X-COINS-TOKEN', $getTokenHeader(config.data))
    }
  })

  // Inject to context as $api
  inject('api', api)
  inject('upload', upload)
  inject('load', load)
}
